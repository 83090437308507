<template>
  <v-row class="add-advertising-row" style="flex-direction: column">
    <v-col class="add-advertising mt-2" md="6" offset-md="3" sm="12">
      <Progress :val="getProgressAmount" v-if="getRouteName !== 'AllDone'" />
    </v-col>
    <v-col
      class="add-advertising"
      :md="
        getRouteName === 'ShowYourAddress' ||
        getRouteName === 'ChoosePlan' ||
        getRouteName === 'ApproveOrEdit'
          ? 10
          : 6
      "
      :offset-md="
        getRouteName === 'ShowYourAddress' ||
        getRouteName === 'ChoosePlan' ||
        getRouteName === 'ApproveOrEdit'
          ? 1
          : 3
      "
      sm="12"
    >
      <transition mode="out-in" name="fade">
        <router-view />
      </transition>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "AddAdvertising",
  components: {
    Progress: () => import("../../components/Account/Progress"),
  },
  computed: {
    getRouteName() {
      return this.$route.name;
    },
    getProgressAmount() {
      const getRouteUrl = this.$route.name;
      let val = 100 / 9;
      if (getRouteUrl === "AdvertisingGoals") return val;
      else if (getRouteUrl === "BasicInfo") return 2 * val;
      else if (getRouteUrl === "SuggestKeywords") return 3 * val;
      else if (getRouteUrl === "ShowYourAddress") return 4 * val;
      else if (getRouteUrl === "ApproveOrEdit") return 5 * val;
      else if (getRouteUrl === "SetBudget") return 6 * val;
      else if (getRouteUrl === "ReviewCompany") return 7 * val;
      else if (getRouteUrl === "ChoosePlan") return 8 * val;
      else if (getRouteUrl === "Payment") return 9 * val;
      return 100;
    },
  },
};
</script>

<style lang="scss" scoped>
.show-address {
  padding: 0 60px;
}

.fade-enter-active,
.component-fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter, .component-fade-leave-to
    /* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
