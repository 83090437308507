var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"add-advertising-row",staticStyle:{"flex-direction":"column"}},[_c('v-col',{staticClass:"add-advertising mt-2",attrs:{"md":"6","offset-md":"3","sm":"12"}},[(_vm.getRouteName !== 'AllDone')?_c('Progress',{attrs:{"val":_vm.getProgressAmount}}):_vm._e()],1),_c('v-col',{staticClass:"add-advertising",attrs:{"md":_vm.getRouteName === 'ShowYourAddress' ||
      _vm.getRouteName === 'ChoosePlan' ||
      _vm.getRouteName === 'ApproveOrEdit'
        ? 10
        : 6,"offset-md":_vm.getRouteName === 'ShowYourAddress' ||
      _vm.getRouteName === 'ChoosePlan' ||
      _vm.getRouteName === 'ApproveOrEdit'
        ? 1
        : 3,"sm":"12"}},[_c('transition',{attrs:{"mode":"out-in","name":"fade"}},[_c('router-view')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }